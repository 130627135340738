import React, { ChangeEvent, useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import InputSelect2 from '~/components/Inputs/InputSelect';
import Separator from '~/components/Separator';
import Loja from '~/components/Loja';
import FormActions from './FormActions';

import { Select } from '../../protocols';

import { useFinalizadora, useFormContext } from '../../FinalizadoraContext';
import { tipoFinalizadoras, tipoOperacao } from '../../data';
import { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import InputNumberDias from '~/components/Inputs/inputNumberDias';
import ToggleDefault from '~/components/ToggleDefault';

import { AlertBox } from './styles';
import { InputSelect, InputText } from '~/components/NovosInputs';

export const Form: React.FC = () => {
  const {
    condicoes,
    contas,
    categorias,
    formFinalizadoraLoja,
    onChangeFormFinalizadoraLoja,
    initLoja,
    getContas,
    formFinalizadora,
    onChangeFormFinalizadora,
    handleClearForm,
    setFlagConfTestAuto,
    flagConfTesAuto,
  } = useFinalizadora();

  const {
    register,
    setValue,
    formState: { errors },
    getValues,
    clearErrors,
    control,
    formStateFinalizadora,
    FinalizadoraRegister,
    FinalizadoraControl,
    setValueFinalizadora,
  } = useFormContext();

  const [tipoFinalizadora, setTipoFinalizadora] = useState<any>([]);

  const verifyIfIsNumbers = (value: string): boolean => {
    const regex = /^[0-9.]+$/;
    checkifTeclaIsInvalid();
    return regex.test(value);
  };

  useEffect(() => {
    setTipoFinalizadora(getValues('tipo_finalizadora'));
  }, [getValues, handleClearForm]);

  const checkifTeclaIsInvalid = (): string => {
    if (formFinalizadoraLoja.des_tecla.isInvalid && initLoja) {
      return 'alert-class';
    }
    return '';
  };

  return (
    <>
      <Row>
        <Col sm={12} md={7}>
          <InputText
            label="Descrição *"
            maxLength={100}
            placeholder="Digite aqui a Descrição"
            name="des_finalizadora"
            register={register}
            toLowerCase={false}
            disabled={false}
            isError={!!errors.des_finalizadora}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputSelect
            label="Tipo *"
            placeholder="Selecione"
            name="tipo_finalizadora"
            register={register}
            isError={!!errors.tipo_finalizadora}
            control={control}
            options={tipoFinalizadoras}
            changeSelected={(selected) => {
              clearErrors('tipo_finalizadora');
              if (selected.value !== 1) {
                setValue('tipo_operacao_cartao', -1);
              }
              setTipoFinalizadora(selected);
              setValue('tipo_finalizadora', selected);
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputSelect
            label="Tipo Operação"
            placeholder="Nenhum"
            name="tipo_operacao_cartao"
            register={register}
            isDisabled={tipoFinalizadora?.value !== 1}
            isError={!!errors.tipo_operacao_cartao}
            control={control}
            options={tipoOperacao}
            changeSelected={(selected) => {
              clearErrors('tipo_operacao_cartao');
              setValue('tipo_operacao_cartao', selected);
            }}
          />
        </Col>
        <Col sm={12} md={1}>
          <ToggleDefault
            labelText="PDV?"
            setChecked={formFinalizadora.flg_pdv.value}
            onSwitch={() => {
              setValue('flg_pdv', !formFinalizadora.flg_pdv.value);
              onChangeFormFinalizadora({
                ...formFinalizadora,
                flg_pdv: {
                  ...formFinalizadora.flg_pdv,
                  value: !formFinalizadora.flg_pdv.value,
                },
              });
            }}
          />
        </Col>
        <Col
          sm={12}
          md={2}
          style={{
            marginTop: '20px',
          }}
        >
          <ToggleDefault
            labelText="Conferência Auto Tesouraria?"
            setChecked={flagConfTesAuto}
            onSwitch={() => {
              setFlagConfTestAuto(!flagConfTesAuto);
            }}
          />
        </Col>
        <div>
          <Separator labelText="Por Loja" />
        </div>
        <Col
          sm={12}
          md={9}
          style={{
            marginTop: '10px',
          }}
        >
          <Loja
            selectedLoja={formFinalizadoraLoja.cod_loja.value || undefined}
            IsInvalid={formFinalizadoraLoja.cod_loja.isInvalid}
            onChangedObject={(value) => {
              setValueFinalizadora('cod_loja', value.value);
              setValueFinalizadora('des_loja', value.label);
              setValueFinalizadora('cod_cc', '');
              getContas(value.value);
              onChangeFormFinalizadoraLoja({
                ...formFinalizadoraLoja,
                cod_loja: {
                  ...formFinalizadoraLoja.cod_loja,
                  value: value.value,
                  isInvalid: value.value === undefined,
                },
                des_loja: {
                  ...formFinalizadoraLoja.des_loja,
                  value: value.label,
                },
              });
            }}
            onChange={() => {
              return false;
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <AlertBox>
            <Field label="Tecla" name="Tecla">
              {({ fieldProps }) => (
                <div className={checkifTeclaIsInvalid()}>
                  <TextField
                    {...fieldProps}
                    type="number"
                    maxLength={25}
                    value={Number(formFinalizadoraLoja.des_tecla.value)}
                    isDisabled={false}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (!(e.target.value === '')) {
                        if (!verifyIfIsNumbers(e.target.value)) {
                          return e.preventDefault();
                        }
                      }

                      setValueFinalizadora(
                        'des_tecla',
                        e.target.value.length < 25
                          ? e.target.value
                          : e.target.value.substring(0, 25),
                      );
                      onChangeFormFinalizadoraLoja({
                        ...formFinalizadoraLoja,
                        des_tecla: {
                          ...formFinalizadoraLoja.des_tecla,
                          value:
                            e.target.value.length < 25
                              ? e.target.value
                              : e.target.value.substring(0, 25),
                          isInvalid: e.target.value.trim() === '',
                        },
                      });
                    }}
                    style={{ textAlign: 'right' }}
                  />
                </div>
              )}
            </Field>
          </AlertBox>
        </Col>
        <Col sm={12} md={1}>
          <ToggleDefault
            labelText="Inativa?"
            setChecked={formFinalizadoraLoja.flg_inativa.value}
            onSwitch={() => {
              setValueFinalizadora(
                'flg_inativa',
                !formFinalizadoraLoja.flg_inativa.value,
              );
              onChangeFormFinalizadoraLoja({
                ...formFinalizadoraLoja,
                flg_inativa: {
                  ...formFinalizadoraLoja.flg_inativa,
                  value: !formFinalizadoraLoja.flg_inativa.value,
                },
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={1} style={{ minWidth: '110px' }}>
          <>
            <InputNumberDias
              label="Condição"
              min={0}
              value={formFinalizadoraLoja.num_condicao.value}
              placeholder=""
              isDisabled={false}
              onChange={(val) => {
                setValueFinalizadora('num_condicao', Number(val));
                onChangeFormFinalizadoraLoja({
                  ...formFinalizadoraLoja,
                  num_condicao: {
                    ...formFinalizadoraLoja.num_condicao,
                    value: Number(val),
                  },
                });
              }}
            />
          </>
        </Col>
        <Col sm={12} md={3} style={{ minWidth: '110px' }}>
          <InputSelect2
            label="Tipo da Condição"
            value={
              condicoes.find((item: Select) => {
                return (
                  item.value === formFinalizadoraLoja.cod_condicao.value.value
                );
              }) ?? {
                value: undefined,
                label: '- Selecione a condição -',
              }
            }
            options={condicoes}
            isRequired={formFinalizadoraLoja.cod_condicao.isRequired}
            setInvalid={
              formFinalizadoraLoja.cod_condicao.isInvalid &&
              !!formStateFinalizadora.errors.cod_condicao
            }
            iniInicializado={initLoja}
            onChange={(value: Select) => {
              setValueFinalizadora('cod_condicao', value);
              onChangeFormFinalizadoraLoja({
                ...formFinalizadoraLoja,
                cod_condicao: {
                  ...formFinalizadoraLoja.cod_condicao,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </Col>
        <Col sm={12} md={4} style={{ maxWidth: '315px' }}>
          <InputSelect
            label="Conta *"
            placeholder="Selecione"
            name="cod_cc"
            register={FinalizadoraRegister}
            isError={!!formStateFinalizadora.errors.cod_cc && initLoja}
            control={FinalizadoraControl}
            options={contas}
            changeSelected={(selected) => {
              setValueFinalizadora('cod_cc', selected);
            }}
          />
        </Col>
        <Col sm={12} md={4}>
          <InputSelect
            label="Categoria *"
            placeholder="Selecione"
            name="cod_categoria"
            ListaWidth="470px"
            register={FinalizadoraRegister}
            isError={!!formStateFinalizadora.errors.cod_categoria && initLoja}
            control={FinalizadoraControl}
            options={categorias}
            changeSelected={(selected) => {
              setValueFinalizadora('cod_categoria', selected);
            }}
          />
        </Col>
      </Row>

      <FormActions />
    </>
  );
};
