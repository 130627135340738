import React, { useEffect, useState } from 'react';
import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid';

import { ButtonRow, TableContainer, CutomizedTooltip } from './styles';
import Tooltip from '@atlaskit/tooltip';

import { useFinalizadora } from '../../FinalizadoraContext';

import { FinalizadoraLoja, FormFinalizadoraLoja } from '../../protocols';
import { GoPencil } from 'react-icons/go';
import { CustomDataGrid, CustomGridColumns } from '../CustomDataGrid';

export const Table: React.FC = () => {
  const {
    categorias,
    condicoes,
    formFinalizadora,
    onChangeFormFinalizadoraLoja,
    onChangeFormFinalizadoraValue,
    contasAll,
    setOnEdit,
  } = useFinalizadora();
  const [finalizadoraLojas, setFinalizadoraLojas] = useState<
    FinalizadoraLoja[]
  >([]);

  useEffect(() => {
    const data: FinalizadoraLoja[] = formFinalizadora.lojas.lojas.map(
      (finalizadoraLoja: FormFinalizadoraLoja) => {
        return {
          id: finalizadoraLoja.cod_loja.value,
          cod_loja: finalizadoraLoja.cod_loja.value,
          des_loja: finalizadoraLoja.des_loja.value || '',
          des_tecla: finalizadoraLoja.des_tecla.value || null,
          num_condicao: finalizadoraLoja.num_condicao.value || 0,
          cod_condicao: finalizadoraLoja.cod_condicao.value.value,
          cod_cc: finalizadoraLoja?.cod_cc?.value?.value || 0,
          cod_categoria: finalizadoraLoja.cod_categoria.value.value || 0,
          flg_inativa: finalizadoraLoja.flg_inativa.value,
        };
      },
    );

    setFinalizadoraLojas(data);
  }, [formFinalizadora.lojas.lojas]);

  const columns: CustomGridColumns[] = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_loja',
      headerName: 'Loja',
      width: 200,
      disableColumnMenu: true,
      renderCell: (row: any) => {
        return (
          <div
            className="text-truncate"
            style={{
              position: 'relative',
              width: '200px',
            }}
          >
            {row.des_loja}
          </div>
        );
      },
    },
    {
      field: 'flg_ativa',
      headerName: 'Inativa?',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (row: any) => {
        return <>{row.flg_inativa ? 'Sim' : 'Não'}</>;
      },
    },
    {
      field: 'des_tecla',
      headerName: 'Tecla',
      width: 70,
      disableColumnMenu: true,
      // disableReorder: true,
      sortable: false,
      renderCell: (row: any) => {
        return <>{row?.des_tecla ? row.des_tecla : '0'}</>;
      },
    },
    {
      field: 'num_condicao',
      headerName: 'Condição',
      width: 120,
      disableColumnMenu: true,
      renderCell: (row: any) => {
        return <>{`${row.num_condicao} Dias`}</>;
      },
    },
    {
      field: 'des_condicao',
      headerName: 'Tipo da Condição',
      width: 200,
      disableColumnMenu: true,
      renderCell: (row: any) => {
        return (
          <>
            {
              condicoes.find((condicao) => row.cod_condicao === condicao.value)
                ?.label
            }
          </>
        );
      },
    },
    {
      field: 'cod_cc',
      headerName: 'CC',
      width: 140,
      disableColumnMenu: true,
      renderCell: (row: any) => {
        return (
          <>
            {' '}
            {contasAll.find((conta: any) => row.cod_cc === conta.value)?.label}
          </>
        );
      },
    },
    {
      field: 'des_categoria',
      headerName: 'Categoria',
      width: 220,
      disableColumnMenu: true,
      renderCell: (row: any) => {
        const descricao = categorias.find(
          (categoria) => row.cod_categoria === categoria.value,
        )?.label;
        return (
          <Tooltip
            component={CutomizedTooltip}
            position="top"
            content={descricao}
          >
            <div
              className="text-truncate"
              style={{
                position: 'relative',
                width: '200px',
              }}
            >
              {descricao}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: '',
      headerName: 'Ações',
      minWidth: 70,
      width: 125,
      sortable: false,
      disableColumnMenu: true,
      fixed: true,
      fixedDirection: 'left',
      renderCell: (row: any) => {
        return (
          <>
            <ButtonRow
              type="button"
              onClick={() => {
                setOnEdit(true);
                onEditItem(row.cod_loja);
              }}
              title="Editar"
            >
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const onEditItem = async (codLoja: number) => {
    const FindFormFinalizadoraLoja = formFinalizadora.lojas.lojas.find(
      (loja: FormFinalizadoraLoja) => {
        return loja.cod_loja.value === codLoja;
      },
    );

    if (
      FindFormFinalizadoraLoja?.cod_cc.value?.label === undefined ||
      FindFormFinalizadoraLoja?.cod_cc.value?.label === ''
    ) {
      const [codConta] = contasAll.filter((item: any) => {
        if (FindFormFinalizadoraLoja?.cod_cc?.value?.value === item?.value) {
          return item;
        }
        return false;
      });
      if (FindFormFinalizadoraLoja?.cod_cc) {
        FindFormFinalizadoraLoja.cod_cc.value = codConta;
      }
    }

    if (FindFormFinalizadoraLoja?.cod_categoria?.value?.label === '') {
      const [codCategoria] = categorias.filter((item: any) => {
        if (
          FindFormFinalizadoraLoja?.cod_categoria?.value?.value === item?.value
        ) {
          return item;
        }
        return false;
      });
      FindFormFinalizadoraLoja.cod_categoria.value = codCategoria;
    }

    if (FindFormFinalizadoraLoja?.cod_condicao?.value?.label === '') {
      const [codCondicao] = condicoes.filter((item: any) => {
        if (
          FindFormFinalizadoraLoja?.cod_condicao.value?.value === item?.value
        ) {
          return item;
        }
        return false;
      });
      FindFormFinalizadoraLoja.cod_condicao.value = codCondicao;
    }

    if (FindFormFinalizadoraLoja) {
      onChangeFormFinalizadoraLoja(FindFormFinalizadoraLoja);
      onChangeFormFinalizadoraValue(FindFormFinalizadoraLoja);
    }
  };

  return (
    <TableContainer>
      <CustomDataGrid rows={finalizadoraLojas} columns={columns} />
      {/* <DataGrid
        rows={finalizadoraLojas}
        columns={columns}
        pageSize={5}
        disableSelectionOnClick
        localeText={{
          noRowsLabel: 'Nenhum registro encontrado...',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar columnas',
        }}
      /> */}
    </TableContainer>
  );
};
